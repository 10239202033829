* {
    max-width: 100%;
}

.no-padding {
    .ant-popover-inner-content {
        padding: 0;
    }
    .ant-popover-arrow {
        border-color: transparent !important;
    }

    // Modal
    .ant-modal-body {
        padding: 0;
    }
}

.no-bg {
    .ant-popover-inner {
        background-color: transparent;
        box-shadow: none;
    }

    // Modal
    .ant-modal-content {
        background-color: transparent;
    }
}

.dark-arrow {
    .ant-popover-arrow {
        background-color: #554764;
    }
}

// margin
.mr-30 {
    margin-right: 30px;
}
.ml-30 {
    margin-left: 30px;
}
.mt-30 {
    margin-top: 30px;
}
.mb-30 {
    margin-bottom: 30px;
}
.m-30 {
    margin: 30px;
}
.mr-20 {
    margin-right: 20px;
}
.ml-20 {
    margin-left: 20px;
}
.mt-20 {
    margin-top: 20px;
}
.mb-20 {
    margin-bottom: 20px;
}
.m-20 {
    margin: 20px;
}
.mr-10 {
    margin-right: 10px;
}
.ml-10 {
    margin-left: 10px;
}
.mt-10 {
    margin-top: 10px;
}
.mb-10 {
    margin-bottom: 10px;
}
.m-10 {
    margin: 10px;
}

// padding
.pr-10 {
    padding-right: 10px;
}
.pl-10 {
    padding-left: 10px;
}
.pt-10 {
    padding-top: 10px;
}
.pb-10 {
    padding-bottom: 10px;
}
.pr-20 {
    padding-right: 20px;
}
.pl-20 {
    padding-left: 20px;
}
.pt-20 {
    padding-top: 20px;
}
.pb-20 {
    padding-bottom: 20px;
}
.pr-30 {
    padding-right: 30px;
}
.pl-30 {
    padding-left: 30px;
}
.pt-30 {
    padding-top: 30px;
}
.pb-30 {
    padding-bottom: 30px;
}
.p-10 {
    padding: 10px;
}
.p-20 {
    padding: 20px;
}
.p-30 {
    padding: 30px;
}
// font-size
.fs-12 {
    font-size: 12px;
}
.fs-14 {
    font-size: 14px;
}
.fs-16 {
    font-size: 16px;
}
.fs-18 {
    font-size: 18px;
}
.fs-20 {
    font-size: 20px;
}
.fs-22 {
    font-size: 22px;
}
.fs-24 {
    font-size: 24px;
}
.fs-26 {
    font-size: 26px;
}
.fs-28 {
    font-size: 20px;
}
.fs-32 {
    font-size: 32px;
}

// font-weight
.fw-300 {
    font-weight: 300;
}
.fw-400 {
    font-weight: 400;
}
.fw-500 {
    font-weight: 500;
}
.fw-600 {
    font-weight: 600;
}
.fw-700 {
    font-weight: 700;
}
.fw-bold {
    font-weight: bold;
}
.fw-normal {
    font-weight: normal;
}

// flex
.d-flex {
    display: flex;
}
.justify-content-start {
    justify-content: flex-start !important;
}
.justify-content-center {
    justify-content: center !important;
}
.justify-content-end {
    justify-content: flex-end !important;
}
.justify-content-between {
    justify-content: space-between !important;
}
.justify-content-around {
    justify-content: space-around !important;
}

.align-items-start {
    align-items: flex-start !important;
}
.align-items-center {
    align-items: center;
}
.align-items-end {
    align-items: flex-end;
}
.align-items-stretch {
    align-items: stretch;
}
.flex-direction-column {
    flex-direction: column;
}

.flex-1 {
    flex: 1;
}
.flex-2 {
    flex: 2;
}
.flex-3 {
    flex: 3;
}
.flex-4 {
    flex: 4;
}

.vertical-align-middle {
    vertical-align: middle;
}
.vertical-align-top {
    vertical-align: top;
}
.vertical-align-bottom {
    vertical-align: bottom;
}

.text-align-center {
    text-align: center;
}
.text-align-right {
    text-align: right;
}
.text-align-left {
    text-align: left;
}

.text-decoration-underline {
    text-decoration: underline;
}

.relative {
    position: relative;
}
.absolute {
    position: absolute;
}
.sticky {
    position: sticky;
}
.fixed {
    position: fixed;
}

.red {
    color: red;
}

// border-radius
.border-radius-4 {
    border-radius: 4px;
}
.border-radius-8 {
    border-radius: 8px;
}
.border-radius-12 {
    border-radius: 12px;
}
.border-radius-16 {
    border-radius: 16px;
}
.border-radius-20 {
    border-radius: 20px;
}
.corner-round {
    border-radius: 20px;
}

// object-fit
.object-fit-cover {
    object-fit: cover;
}
.object-fit-contain {
    object-fit: contain;
}

.width-100p {
    width: 100%;
}
.height-100p {
    height: 100%;
}
.width-height-100p {
    width: 100%;
    height: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

.link-color {
    color: #2f80ed;
}

.no-wrap {
    white-space: nowrap;
}

.flex-wrap {
    flex-wrap: wrap;
}
.flex-nowrap {
    flex-wrap: nowrap;
}

.w-100p {
    width: 100%;
}
.h-100p {
    height: 100%;
}
.d-none {
    display: none;
}

.active-mask:active,
.active-mask:hover {
    opacity: 0.6;
}

.user-select-none {
    user-select: none;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.overflow-ellipsis-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}

.show-scrollbar::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
    cursor: pointer;
}
.show-scrollbar::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 5px;
    background-color: rgba(85, 71, 100, 0.08);
    border: 1px solid rgba(85, 71, 100, 0.08);
}
.hide-scrollbar::-webkit-scrollbar,
.hide-scrollbar::-webkit-scrollbar-thumb {
    display: none;
}

.add-to-cart:disabled {
    opacity: 0.5;
}
