.page-width {
    max-width: 1300px;
    margin: 0 auto;
}

@media screen and (min-width: 769px) {
    .mobile-show {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .desktop-show {
        display: none;
    }
}

.hover-underline {
    color: inherit;
    position: relative;
    cursor: pointer;
    text-decoration: none !important;
}

.hover-underline:hover {
    &:after {
        width: 100%;
        left: 0;
    }
}

.hover-underline:after {
    background: none repeat scroll 0 0 transparent !important;
    bottom: 0;
    content: "";
    display: block;
    height: 1.5px;
    left: 50%;
    position: absolute;
    background: black !important;
    -webkit-transition: width 0.3s ease 0s, left 0.3s ease 0s;
    -o-transition: width 0.3s ease 0s, left 0.3s ease 0s;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.headroom {
    will-change: transform;
    transition: transform 200ms linear;
}

.headroom--pinned {
    transform: translateY(0%);
}

.headroom--unpinned {
    transform: translateY(-100%);
}
