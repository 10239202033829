// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

// @primary-color: #1890ff;
/* You can add global styles to this file, and also import other style files */

@import "./app/styles/base.less";
@import "./app/styles/temp.less";

@theme: default;

// The prefix to use on all css classes from ant.
@ant-prefix: ant;

// An override for the html selector for theme prefixes
@html-selector: html;

// -------- Colors -----------
@primary-color: #666;

@border-color-base: #000;
@select-item-selected-bg:rgba (0,0,0,0.5);

body {
    letter-spacing: 0.02em;
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

ol,
ul,
dl {
    padding-inline-start: 14px;
}

.ant-btn {
    border-color: transparent;
}
